import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService
  ) {}

  getAppStatus() {
    return this.httpClient.post(
      environment.apiUrl + '/getAppStatus',
      {},
      httpOptions
    );
  }

  // AUTH API

  login(email: string, password: string) {
    return this.httpClient.post(
      environment.apiUrl + '/login',
      { email, password, source: 'NEW_SITE' },
      httpOptions
    );
  }

  isLoggedIn() {
    return this.httpClient.post(
      environment.apiUrl + '/isLoggedIn',
      { source: 'NEW_SITE' },
      httpOptions
    );
  }

  getDashboardCount() {
    return this.httpClient.post(
      environment.apiUrl + '/getDashboardCount',
      httpOptions
    );
  }

  logout() {
    return this.httpClient.post(environment.apiUrl + '/esci', {}, httpOptions);
  }

  updateInfo(
    lang: string | null = null,
    name: string | null = null,
    surname: string | null = null,
    yearBirth: string | null = null,
    countryID: string | null = null,
    phone: string | null = null,
    gender: string | null = null
  ) {
    return this.httpClient.post(
      environment.apiUrl + '/updateInfo',
      { lang, name, surname, yearBirth, countryID, phone, gender },
      httpOptions
    );
  }

  updatePassword(body: any) {
    return this.httpClient.post(
      environment.apiUrl + '/updatePassword',
      body,
      httpOptions
    );
  }

  signin(body: any) {
    return this.httpClient.post(
      environment.apiUrl + '/signin',
      body,
      httpOptions
    );
  }

  getCountries() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getCountries',
      { lang },
      httpOptions
    );
  }

  getAllCountries() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getAllCountries',
      { lang },
      httpOptions
    );
  }

  getMapCountries() {
    return this.httpClient.post(
      environment.apiUrl + '/getAllCountries',
      { lang: 'EN' },
      httpOptions
    );
  }

  getCountriesForVariety(ref: string, refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getCountriesForVariety',
      { lang, ref, refID },
      httpOptions
    );
  }

  getStatsCountries() {
    return this.httpClient.post(
      environment.apiUrl + '/getStatsCountries',
      {},
      httpOptions
    );
  }

  getStatsCountriesByFamily(ref: string, refID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/getStatsCountriesByFamily',
      { ref, refID },
      httpOptions
    );
  }

  resetPassword(email: string) {
    return this.httpClient.post(
      environment.apiUrl + '/resetPassword',
      { email },
      httpOptions
    );
  }

  //Settings
  changeRole(roleID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/changeRole',
      { roleID },
      httpOptions
    );
  }

  changeRoleType(role: string) {
    return this.httpClient.post(
      environment.apiUrl + '/changeRoleType',
      { role },
      httpOptions
    );
  }

  changeRoleRef(ref: string) {
    return this.httpClient.post(
      environment.apiUrl + '/changeRoleRef',
      { ref },
      httpOptions
    );
  }

  updateRole(body: any) {
    body.lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/updateRole',
      body,
      httpOptions
    );
  }

  removeRole(refID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/removeRole',
      { refID },
      httpOptions
    );
  }

  // DATABASE API

  getCrops() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getCrops',
      { lang },
      httpOptions
    );
  }

  getFitlteredCrops(
    filter?: string | null,
    ready: boolean = true,
    notReady: boolean = true,
    refType: string | null = null
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getCropsWithFilter',
      { lang, filter, ready, notReady, refType },
      httpOptions
    );
  }

  getRefTypes(ref: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getRefTypes',
      { lang, ref },
      httpOptions
    );
  }

  getFilteredLivestocks(
    filter?: string | null,
    ready: boolean = true,
    notReady: boolean = true,
    refType: string | null = null
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getLivestocksWithFilter',
      { lang, filter, ready, notReady, refType },
      httpOptions
    );
  }

  getLivestocks() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getLivestocks',
      { lang },
      httpOptions
    );
  }

  getAquaculture() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getAquatics',
      { lang },
      httpOptions
    );
  }

  getFilteredAquaculture(
    filter?: string | null,
    ready: boolean = true,
    notReady: boolean = true,
    refType: string | null = null
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getAquaticsWithFilter',
      { lang, filter, ready, notReady, refType },
      httpOptions
    );
  }

  getDescriptors(traitsRef: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getDescriptors',
      { lang, traitsRef },
      httpOptions
    );
  }

  getDescriptor(traitID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getDescriptor',
      { lang, traitID },
      httpOptions
    );
  }

  getDescriptorByCategory(traitsSubtypeID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getDescriptorsByCategory',
      { lang, traitsSubtypeID },
      httpOptions
    );
  }

  getManagementDescriptor(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getManagementDescriptor',
      { refID, lang },
      httpOptions
    );
  }

  getManagementCategories(ref: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getManagementCategories',
      { ref, lang },
      httpOptions
    );
  }

  getManagementTable(ref: string, refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getManagementTable',
      { lang, ref, refID },
      httpOptions
    );
  }

  getMarketCategories(ref: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMarketCategories',
      { ref, lang },
      httpOptions
    );
  }

  getGoals(traitsRef: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getGoals',
      { lang, traitsRef },
      httpOptions
    );
  }

  getGoalSelected(
    refID: string,
    goalID: string,
    siteID: string | null = null,
    familyID: string | null = null
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getGoalSelected',
      { lang, refID, goalID, siteID, familyID },
      httpOptions
    );
  }

  getSubgoalDescriptors(subgoalID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getSubgoalDescriptors',
      { lang, subgoalID },
      httpOptions
    );
  }

  getSubgoalManagement(subgoalID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getSubgoalManagement',
      { lang, subgoalID },
      httpOptions
    );
  }

  getSubgoalMarket(subgoalID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getSubgoalMarket',
      { lang, subgoalID },
      httpOptions
    );
  }

  getSubgoalPolicy(subgoalID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getSubgoalPolicy',
      { lang, subgoalID },
      httpOptions
    );
  }

  getConstraints(ref: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getConstraints',
      { lang, ref },
      httpOptions
    );
  }

  getInterventionsForConstraint(ref: string, constraintID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getInterventionsForConstraint',
      { lang, ref, constraintID },
      httpOptions
    );
  }

  getInterventionCategories(ref: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getInterventionCategories',
      { lang, ref },
      httpOptions
    );
  }

  getIntervention(ref: string, interventionID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getIntervention',
      { lang, ref, interventionID },
      httpOptions
    );
  }

  getPolicyCategories(ref: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getPolicyCategories',
      { lang, ref },
      httpOptions
    );
  }

  getInstitutionType() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getInstitutionType',
      { lang },
      httpOptions
    );
  }

  getCrop(cropID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getCrop',
      { lang, cropID },
      httpOptions
    );
  }

  getLivestock(livestockID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getLivestock',
      { lang, livestockID },
      httpOptions
    );
  }

  getAquatic(aquaticID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getAquatic',
      { lang, aquaticID },
      httpOptions
    );
  }

  getSpecies(cropID: string, aquaticID: string, livestockID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getSpecies',
      { lang, cropID, aquaticID, livestockID },
      httpOptions
    );
  }

  getVarieties(
    cropID: string,
    filter: string | null = null,
    countryID: string | null = null
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getVarieties',
      { lang, cropID, filter, countryID },
      httpOptions
    );
  }

  getVarietyInfo(ref: string, refID: string, countryID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getVarietyInfo',
      { lang, ref, refID, countryID },
      httpOptions
    );
  }

  getVarietiesOtherCountries(cropID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getVarietiesOtherCountries',
      { lang, cropID },
      httpOptions
    );
  }

  getBreedsOtherCountries(livestockID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getBreedsOtherCountries',
      { lang, livestockID },
      httpOptions
    );
  }

  importVarietyToMyCountry(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/importVarietyToMyCountry',
      { lang, refID },
      httpOptions
    );
  }

  importBreedToMyCountry(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/importBreedToMyCountry',
      { lang, refID },
      httpOptions
    );
  }

  getSpeciesSelect(
    cropID?: string | null,
    livestockID?: string | null,
    aquaticID?: string | null
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getSpeciesSelect',
      {
        lang,
        cropID,
        livestockID,
        aquaticID,
      },
      httpOptions
    );
  }

  getVarietySelect(cropID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getVarietySelect',
      { lang, cropID },
      httpOptions
    );
  }

  getBreedSelect(livestockID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getBreedSelect',
      { lang, livestockID },
      httpOptions
    );
  }

  addVariety(body: any) {
    return this.httpClient.post(
      environment.apiUrl + '/addVariety',
      body,
      httpOptions
    );
  }

  addBreed(body: any) {
    return this.httpClient.post(
      environment.apiUrl + '/addBreed',
      body,
      httpOptions
    );
  }

  getCountryByCrop(
    cropID: string,
    filter: string | null = null,
    countryID: string | null = null
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getCountryByCrop',
      { lang, cropID, filter, countryID },
      httpOptions
    );
  }

  getCountryByLivestock(
    livestockID: string,
    filter: string | null = null,
    countryID: string | null = null
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getCountryByLivestock',
      { lang, livestockID, filter, countryID },
      httpOptions
    );
  }

  getBreeds(
    livestockID: string,
    filter: string | null = null,
    countryID: string | null = null
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getBreeds',
      { lang, livestockID, filter, countryID },
      httpOptions
    );
  }

  getFarmedTypes(aquaticID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getFarmedTypes',
      { lang, aquaticID },
      httpOptions
    );
  }

  getMeasureSelect() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMeasureSelect',
      { lang },
      httpOptions
    );
  }

  getDescriptorsRef(ref: string, refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getDescriptorsRef',
      { lang, ref, refID },
      httpOptions
    );
  }

  getMarketTable(ref: string, refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMarketTable',
      { lang, ref, refID },
      httpOptions
    );
  }

  getVariety(varietyID: string, cropID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getVariety',
      { lang, varietyID, cropID, source: 'NEW_SITE' },
      httpOptions
    );
  }

  getVarietyDescriptorAnswer(
    refID: string,
    countryID: string,
    page: number,
    typeID: string | null,
    filter: string | null,
    subtypeID: string | null
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getVarietyDescriptorAnswer',
      { lang, refID, countryID, page, typeID, filter, subtypeID },
      httpOptions
    );
  }

  getVarietyDescriptorAnswerSubtypeFilter(
    refID: string,
    countryID: string,
    typeID: string
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getVarietyDescriptorAnswerSubtypeFilter',
      { lang, refID, countryID, typeID },
      httpOptions
    );
  }

  getVarietyDescriptorAnswerTypeFilter(refID: string, countryID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getVarietyDescriptorAnswerTypeFilter',
      { lang, refID, countryID },
      httpOptions
    );
  }

  getVarietySuppliers(varietyID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getVarietySuppliers',
      { lang, varietyID },
      httpOptions
    );
  }

  getBreed(breedID: string, livestockID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getBreed',
      { lang, breedID, livestockID },
      httpOptions
    );
  }

  getBreedDescriptorAnswer(
    refID: string,
    countryID: string,
    page: number,
    typeID: string,
    filter: string,
    subtypeID: string
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getBreedDescriptorAnswer',
      { lang, refID, countryID, page, typeID, filter, subtypeID },
      httpOptions
    );
  }

  getBreedDescriptorAnswerTypeFilter(refID: string, countryID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getBreedDescriptorAnswerTypeFilter',
      { lang, refID, countryID },
      httpOptions
    );
  }

  getBreedDescriptorAnswerSubtypeFilter(
    refID: string,
    countryID: string,
    typeID: string
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getBreedDescriptorAnswerSubtypeFilter',
      { lang, refID, countryID, typeID },
      httpOptions
    );
  }

  getImages(refID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/getImages',
      { refID },
      httpOptions
    );
  }

  getImagesOtherCountry(refID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/getImagesOtherCountry',
      { refID },
      httpOptions
    );
  }

  getSupplierTypes() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getSupplierTypes',
      { lang },
      httpOptions
    );
  }

  getSupplierSubtypes() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getSupplierSubtypes',
      { lang },
      httpOptions
    );
  }

  getGeoRegStatus() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getGeoRegStatus',
      { lang },
      httpOptions
    );
  }

  getSupplierCategory() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getSupplierCategory',
      { lang },
      httpOptions
    );
  }

  getSupplierDistTypes() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getSupplierDistTypes',
      { lang },
      httpOptions
    );
  }

  getSupplierDistTo() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getSupplierDistTo',
      { lang },
      httpOptions
    );
  }

  getQualityGuarantees(ref: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getQualityGuarantees',
      { lang, ref },
      httpOptions
    );
  }

  getMaterialTypeClassification(ref: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMaterialTypeClassification',
      { lang, ref },
      httpOptions
    );
  }

  getReproductiveMaterial(ref: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getReproductiveMaterial',
      { lang, ref },
      httpOptions
    );
  }

  getPointSellingType() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getPointSellingType',
      { lang },
      httpOptions
    );
  }

  getCountry(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getCountry',
      { lang, refID },
      httpOptions
    );
  }

  getOtherDb(
    otherDbType: string,
    countryId: string,
    page: number,
    filter: string,
    sort: string,
    direction: string
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getOtherDb',
      { lang, otherDbType, countryId, page, filter, sort, direction },
      httpOptions
    );
  }

  //PROJECTS API

  createProject(body: any) {
    return this.httpClient.post(
      environment.apiUrl + '/createProject',
      body,
      httpOptions
    );
  }

  changeProjectRealTest(refID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/changeProjectRealTest',
      { refID },
      httpOptions
    );
  }

  editTitle(projectID: string, title: string) {
    return this.httpClient.post(
      environment.apiUrl + '/editTitle',
      { projectID, title },
      httpOptions
    );
    // TODO error 403 no permessi
  }

  deleteProjectUser(projectID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/deleteProjectUser',
      { refID: projectID },
      httpOptions
    );
  }

  getFamiliesProject(ref: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getFamiliesProject',
      { lang, ref },
      httpOptions
    );
  }

  getAgroZones(countryID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getAgroZones',
      { lang, countryID },
      httpOptions
    );
  }

  getUserToShare(email: string | null = null, uuid: string | null = null) {
    return this.httpClient.post(
      environment.apiUrl + '/getUserToShare',
      { email, uuid },
      httpOptions
    );
  }

  shareProject(
    projectId: string,
    userID: string,
    name: string,
    surname: string,
    email: string,
    role: string,
    addActivity: boolean,
    shareProject: boolean,
    editTitle: boolean,
    editSite: boolean,
    addSite: boolean
  ) {
    return this.httpClient.post(
      environment.apiUrl + '/shareProject',
      {
        projectId,
        userID,
        name,
        surname,
        email,
        role,
        addActivity,
        shareProject,
        editTitle,
        editSite,
        addSite,
      },
      httpOptions
    );
  }

  getSite(refID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/getSite',
      { refID },
      httpOptions
    );
  }

  getSiteDetail(siteID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getSiteDetail',
      { lang, siteID },
      httpOptions
    );
  }

  updateSite(body: any) {
    return this.httpClient.post(
      environment.apiUrl + '/updateSite',
      body,
      httpOptions
    );
  }

  changeSiteActive(projectID: string, siteID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/changeSiteActive',
      { projectID, siteID },
      httpOptions
    );
  }

  getDescriptorsByFamilyAndType(
    projectID: string,
    cropID: string,
    refID: string
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getDescriptorsByFamilyAndType',
      { lang, projectID, cropID, refID },
      httpOptions
    );
  }
  getAnswersByFamilyAndType(projectID: string, cropID: string, refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getAnswersByFamilyAndType',
      { lang, projectID, cropID, refID },
      httpOptions
    );
  }

  getFgdVarietyInfo(projectID: string, refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getFgdVarietyInfo',
      { lang, projectID, refID },
      httpOptions
    );
  }

  getFgdAnswerInfo(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getFgdAnswerInfo',
      { lang, refID },
      httpOptions
    );
  }

  updateFgdAnswer(refID: string, body: any) {
    const lang = this.translate.currentLang.toUpperCase();
    body.refID = refID;
    body.lang = lang;
    return this.httpClient.post(
      environment.apiUrl + '/updateFgdAnswer',
      body,
      httpOptions
    );
  }

  getActivitiesPoints(refID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/getActivitiesPoints',
      { refID },
      httpOptions
    );
  }

  getFilteredActivities(body: any) {
    return this.httpClient.post(
      environment.apiUrl + '/getActivities',
      body,
      httpOptions
    );
  }

  getActivities(refID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/getActivities',
      { refID },
      httpOptions
    );
  }

  getSurveys(refID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/getSurveys',
      { refID },
      httpOptions
    );
  }

  getActivity(refID: string, activityType: string) {
    return this.httpClient.post(
      environment.apiUrl + '/getActivity',
      { refID, activityType },
      httpOptions
    );
  }

  updateActivity(body: any) {
    return this.httpClient.post(
      environment.apiUrl + '/updateActivity',
      body,
      httpOptions
    );
  }

  getProjectsWithDetail() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getProjectsWithDetail',
      { lang },
      httpOptions
    );
  }

  getPendingRequests() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getPendingRequests',
      { lang },
      httpOptions
    );
  }

  getProjectsDeclined() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getProjectsDeclined',
      { lang },
      httpOptions
    );
  }

  acceptProjectUser(projectID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/acceptProjectUser',
      { refID: projectID },
      httpOptions
    );
  }

  rejectProjectUser(projectID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/rejectProjectUser',
      { refID: projectID },
      httpOptions
    );
  }

  getProject(projectID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getProject',
      { projectID, lang },
      httpOptions
    );
  }

  getProjectUsers(
    refId: string,
    page?: number,
    filter?: string,
    sort?: string,
    direction?: string,
    projectRole?: string | null
  ) {
    return this.httpClient.post(
      environment.apiUrl + '/getProjectUsers',
      { refId, page, filter, sort, direction, projectRole },
      httpOptions
    );
  }

  getSites(projectID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getSites',
      { projectID, lang },
      httpOptions
    );
  }

  getProjectFile(
    filePath: string,
    projectID: string,
    refID: string | null = null
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + filePath,
      { lang, projectID, refID },
      httpOptions
    );
  }

  checkProjectFile(title: string, projectID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/checkProjectFile',
      { lang, title, projectID },
      httpOptions
    );
  }

  //instructions
  getInstruction(type: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getInstruction',
      { type, lang },
      httpOptions
    );
  }

  getFolders(refID: string | null) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getFolders',
      { refID, lang },
      httpOptions
    );
  }

  getFilesByFolder(refID: string | null, filter: string | null) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getFilesByFolder',
      { refID, filter, lang },
      httpOptions
    );
  }

  getParentFolders(refID: string | null) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getParentFolders',
      { refID, lang },
      httpOptions
    );
  }

  //contact us

  sendSupport(body: any) {
    return this.httpClient.post(
      environment.apiUrl + '/sendSupport',
      body,
      httpOptions
    );
  }

  //working area

  createWorkingArea(refID: string, refIDs: string[]) {
    return this.httpClient.post(
      environment.apiUrl + '/createWorkingArea',
      { refID, refIDs },
      httpOptions
    );
  }

  getWorkingArea(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getWorkingArea',
      { refID, lang },
      httpOptions
    );
  }

  getMyWorkingAreas() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMyWorkingAreas',
      { lang },
      httpOptions
    );
  }

  getMyWorkingAreaByFamily(refID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/getMyWorkingAreaByFamily',
      { refID },
      httpOptions
    );
  }

  removeVariety(refID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/removeVariety',
      { refID },
      httpOptions
    );
  }

  editVarietyName(refID: string, name: string) {
    return this.httpClient.post(
      environment.apiUrl + '/editVarietyName',
      { refID, name },
      httpOptions
    );
  }

  editLocalName(body: any) {
    body.lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/editLocalName',
      body,
      httpOptions
    );
  }

  deleteVarietyName(localNameID: string, varietyID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/deleteVarietyName',
      { localNameID, varietyID },
      httpOptions
    );
  }

  getDescriptorsByWorkingArea(
    refID: string,
    filter: string | null,
    refIDs: string[]
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getDescriptorsByWorkingArea',
      { refID, filter, refIDs, lang },
      httpOptions
    );
  }

  getMyLastWorkingAreas() {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMyLastWorkingAreas',
      { lang },
      httpOptions
    );
  }

  getFamilyPartner(filter: string | null = null) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getFamilyPartner',
      { lang, filter },
      httpOptions
    );
  }

  getVarietiesPartner(refId: string, filter: string | null) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getVarietiesPartner',
      { lang, refId, filter },
      httpOptions
    );
  }

  getDescriptorsSelectedByWorkingArea(refID: string, refType: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getDescriptorsSelectedByWorkingArea',
      { refID, refType, lang },
      httpOptions
    );
  }

  getAnswersByWorkingArea(refID: string, refType: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getAnswersByWorkingArea',
      { refID, refType, lang },
      httpOptions
    );
  }

  setDescriptorsByWorkingArea(refID: string, refIDs: string[]) {
    return this.httpClient.post(
      environment.apiUrl + '/setDescriptorsByWorkingArea',
      { refID, refIDs },
      httpOptions
    );
  }

  getVarietiesByWorkingArea(refID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/getVarietiesByWorkingArea',
      { refID },
      httpOptions
    );
  }

  setVarietiesByWorkingArea(refID: string, refIDs: string[]) {
    return this.httpClient.post(
      environment.apiUrl + '/setVarietiesByWorkingArea',
      { refID, refIDs },
      httpOptions
    );
  }

  getAnswerInfoByWorkingArea(
    descriptorID: string,
    answerID: string,
    age: string | null = null,
    gender: string | null = null
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getAnswerInfoByWorkingArea',
      { descriptorID, answerID, lang, age, gender },
      httpOptions
    );
  }

  updateAnswerByWorkingArea(body: any) {
    const lang = this.translate.currentLang.toUpperCase();
    body.lang = lang;
    return this.httpClient.post(
      environment.apiUrl + '/updateAnswerByWorkingArea',
      body,
      httpOptions
    );
  }

  //refid varietyID ref CROPS
  updateGallery(
    refID: string,
    folder: string,
    ref: string,
    type: string,
    fileName: string,
    fileSize: number,
    file: any
  ) {
    httpOptions.headers.set(
      'Content-type',
      'application/x-www-form-urlencoded'
    );
    return this.httpClient.post(
      environment.apiUrl + '/updateGallery',
      { refID, folder, ref, type, fileName, fileSize, file },
      httpOptions
    );
  }

  updateGalleryTest(formData: FormData) {
    return this.httpClient.post(
      environment.apiUrl + '/updateGallery',
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  deleteImage(imageGalleryID: string, folder: string) {
    return this.httpClient.post(
      environment.apiUrl + '/deleteImage',
      { imageGalleryID, folder },
      httpOptions
    );
  }

  getMySupportRequests() {
    return this.httpClient.post(
      environment.apiUrl + '/getMySupportRequests',
      {},
      httpOptions
    );
  }

  getMySupportRequest(refID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/getMySupportRequest',
      { refID },
      httpOptions
    );
  }

  addMySupportRequest(
    refID: string | null,
    subject: string | null,
    message: string | null
  ) {
    return this.httpClient.post(
      environment.apiUrl + '/addMySupportRequest',
      { refID, subject, message },
      httpOptions
    );
  }

  getProjectFiles(refID: string, filter: string | null) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getProjectFiles',
      { refID, filter, lang },
      httpOptions
    );
  }

  getPeriodFiles(refID: string, filter: string | null) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getPeriodFiles',
      { refID, filter, lang },
      httpOptions
    );
  }

  getFileCreated(refId: string, page: number = 0, filter: string | null) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getFileCreated',
      { refId, filter, page, lang },
      httpOptions
    );
  }

  getPeriodFileCreated(refID: string, cleaned: boolean, filter: string | null) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getPeriodFileCreated',
      { refID, filter, cleaned, lang },
      httpOptions
    );
  }

  requestFile(
    refID: string,
    projectFileTypeID: string,
    fileExt: string,
    speciesID: string | null,
    force: boolean = false,
    cleaned: boolean
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/requestFile',
      { refID, projectFileTypeID, fileExt, speciesID, force, cleaned, lang },
      httpOptions
    );
  }

  requestPeriodFile(
    refID: string,
    projectFileTypeID: string,
    fileExt: string,
    speciesID: string | null,
    cleaned: boolean,
    showNote: boolean,
    force: boolean = false
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/requestPeriodFile',
      {
        refID,
        projectFileTypeID,
        fileExt,
        speciesID,
        force,
        showNote,
        cleaned,
        lang,
      },
      httpOptions
    );
  }

  getPeriods(refID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/getPeriods',
      { refID },
      httpOptions
    );
  }

  getPeriod(refID: string) {
    return this.httpClient.post(
      environment.apiUrl + '/getPeriod',
      { refID },
      httpOptions
    );
  }

  closePeriod(refID: string, endDate: number) {
    return this.httpClient.post(
      environment.apiUrl + '/closePeriod',
      { refID, endDate },
      httpOptions
    );
  }

  editPeriod(refID: string, endDate?: number, title?: string) {
    return this.httpClient.post(
      environment.apiUrl + '/editPeriod',
      { refID, endDate, title },
      httpOptions
    );
  }

  getRScripts(ref: string, filter?: string, refID?: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getRScripts',
      { lang, ref, filter, refID },
      httpOptions
    );
  }

  getOtScripts(ref: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getOtScripts',
      { lang, ref },
      httpOptions
    );
  }

  getProjectStats(
    refID: string,
    filter: string | null = null,
    activityType: string | null = null,
    page: number = 0
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getProjectStats',
      { lang, refID, filter, activityType, page },
      httpOptions
    );
  }

  getFamiliesByPeriod(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getFamiliesByPeriod',
      { lang, refID },
      httpOptions
    );
  }

  getSitesByPeriod(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getSitesByPeriod',
      { lang, refID },
      httpOptions
    );
  }

  getStatsForPeriod(
    path: string,
    refID: string,
    familyID: string,
    siteID: string | undefined
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + path,
      { lang, refID, familyID, siteID },
      httpOptions
    );
  }

  getFamiliesCleaning(projectID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getFamiliesCleaning',
      { lang, projectID },
      httpOptions
    );
  }

  getVarietiesCleaning(
    projectID: string,
    refId: string,
    filter?: string,
    siteID?: string
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getVarietiesCleaning',
      { lang, projectID, refId, filter, siteID },
      httpOptions
    );
  }

  requestAgainFile(refID: string, showNote: boolean, cleaned: boolean) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/requestAgainFile',
      { lang, refID, showNote, cleaned },
      httpOptions
    );
  }

  renameVarieties(body: any) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/renameVarieties',
      body,
      httpOptions
    );
  }

  getDescriptorCategoriesByProject(projectID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getDescriptorCategoriesByProject',
      { lang, projectID },
      httpOptions
    );
  }

  getDescriptorsByProject(refID: string, familyID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getDescriptorsByProject',
      { lang, refID, familyID },
      httpOptions
    );
  }

  getAnswerDescriptorByProject(
    projectID: string,
    refID: string,
    refIDs: string[],
    familyID: string
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getAnswerDescriptorByProject',
      { lang, projectID, refID, refIDs, familyID },
      httpOptions
    );
  }

  getAnswerDetailByProject(projectID: string, familyID: string, refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getAnswerDetailByProject',
      { lang, projectID, refID, familyID },
      httpOptions
    );
  }

  getVarietyDetailByProject(projectID: string, familyID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getVarietyDetailByProject',
      { lang, projectID, familyID },
      httpOptions
    );
  }

  getActivitiesByPeriod(refID: string, siteID: string, activityType: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getActivitiesByPeriod',
      { lang, refID, siteID, activityType },
      httpOptions
    );
  }

  switchEnableActivity(refID: string, activityType: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/switchEnableActivity',
      { lang, activityType, refID },
      httpOptions
    );
  }

  splitVarieties(body: any) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/splitVarieties',
      body,
      httpOptions
    );
  }

  getSpeciesByVarieties(refIDs: any) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getSpeciesByVarieties',
      { lang, refIDs },
      httpOptions
    );
  }

  joinVarieties(body: any) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/joinVarieties',
      body,
      httpOptions
    );
  }

  getVarietyHistory(projectID: string, refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getVarietyHistory',
      { projectID, refID, lang },
      httpOptions
    );
  }

  restoreVarietiesFromHistory(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/restoreVarietiesFromHistory',
      { refID },
      httpOptions
    );
  }

  doneVarietiesCleaning(projectID: string, familyID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/doneVarietiesCleaning',
      { projectID, familyID },
      httpOptions
    );
  }

  getVarietyTypes(refID: string, projectID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getVarietyTypes',
      { refID, projectID, lang },
      httpOptions
    );
  }

  setProjectVarietyType(body: any) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/setProjectVarietyType',
      body,
      httpOptions
    );
  }

  getOtCleaning(periodID: string, refID: string, from: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getOtCleaning',
      { periodID, refID, from, lang },
      httpOptions
    );
  }

  getOtStructure(
    link: string,
    refID: string,
    familyID: string,
    measure?: string
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + link,
      { refID, lang, familyID, measure },
      httpOptions
    );
  }

  getOtStructureHeaders(link: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + link + 'Headers',
      { lang },
      httpOptions
    );
  }

  getViewOT(
    link: string,
    periodID: string,
    familyID: string,
    rowID?: string,
    colID?: string,
    refID?: string,
    measure?: string
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + link,
      { refID, lang, periodID, familyID, rowID, colID, measure },
      httpOptions
    );
  }

  editOT(link: string, refID: string, quantity: string, measure: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + link,
      { lang, refID, quantity, measure },
      httpOptions
    );
  }

  historyOT(link: string, refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + link,
      { lang, refID },
      httpOptions
    );
  }

  getMeasureOt(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMeasureOt',
      { lang, refID },
      httpOptions
    );
  }

  getOtMinMax(
    link: string,
    periodID: string,
    familyID: string,
    colID: string,
    refID: string,
    measure?: string
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + link,
      { lang, periodID, familyID, colID, refID, measure },
      httpOptions
    );
  }

  switchDisableAnswer(link: string, refID: string, measure?: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + link,
      { lang, refID, measure },
      httpOptions
    );
  }
  getActivitiesBySiteSelect(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getActivitiesBySiteSelect',
      { lang, refID },
      httpOptions
    );
  }

  createVarietiesCleaning(projectID: string, refId: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/createVarietiesCleaning',
      { lang, projectID, refId },
      httpOptions
    );
  }

  stopVarietiesCleaning(projectID: string, refId: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/stopVarietiesCleaning',
      { lang, projectID, refId },
      httpOptions
    );
  }

  createOtCleaning(periodID: string, refID: string, familyID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/createOtCleaning',
      { lang, periodID, refID, familyID },
      httpOptions
    );
  }
  stopOtCleaning(periodID: string, refID: string, familyID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/stopOtCleaning',
      { lang, periodID, refID, familyID },
      httpOptions
    );
  }
  getDiversityTable(refID: string, siteID?: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getDiversityTable',
      { lang, refID, siteID },
      httpOptions
    );
  }
  getDashboardME(projectID: string, refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getDashboardME',
      { lang, projectID, refID },
      httpOptions
    );
  }
  getFamiliesByProject(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getFamiliesByProject',
      { lang, refID },
      httpOptions
    );
  }

  getPeriodGraphValue(link: string, refID: string, familyID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + link,
      { lang, refID, familyID },
      httpOptions
    );
  }

  setDiversityBenefitAnswer(link: string, refID: string, target: any) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + link,
      { lang, refID, target },
      httpOptions
    );
  }

  getStructureDiversityBenefit(projectID: string, refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getStructureDiversityBenefit',
      { lang, projectID, refID },
      httpOptions
    );
  }

  getSubgoalDevelopmentBenefit(
    refID: string,
    familyID: string,
    periodID: string
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getSubgoalDevelopmentBenefit',
      { lang, familyID, refID, periodID },
      httpOptions
    );
  }

  getAddSubgoalDevelopmentBenefit(
    refID: string,
    familyID: string,
    periodID: string
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getAddSubgoalDevelopmentBenefit',
      { lang, familyID, refID, periodID },
      httpOptions
    );
  }

  getAddDescriptorsDevelopmentBenefit(
    refID: string,
    familyID: string,
    periodID: string
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getAddDescriptorsDevelopmentBenefit',
      { lang, familyID, refID, periodID },
      httpOptions
    );
  }

  addSubgoalsDevelopmentBenefit(
    refIDs: string[],
    familyID: string,
    periodID: string
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/addSubgoalsDevelopmentBenefit',
      { lang, familyID, refIDs, periodID },
      httpOptions
    );
  }

  getGoalDevelopmentBenefit(ref: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getGoalDevelopmentBenefit',
      { lang, ref },
      httpOptions
    );
  }

  getInfoDescriptor(ref: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getInfoDescriptor',
      { lang, ref },
      httpOptions
    );
  }

  addDescriptorDevelopmentBenefit(
    refID: string,
    descriptorID: string,
    minMaxBetween: string,
    form: any
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/addDescriptorDevelopmentBenefit',
      { lang, refID, descriptorID, minMaxBetween, ...form },
      httpOptions
    );
  }

  getStatProjectDevelopmentBenefit(
    link: string,
    refID: string,
    periodID: string
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + link,
      { lang, refID, periodID },
      httpOptions
    );
  }

  getMeConstraints(projectID: string, refID: string, siteID?: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMeConstraints',
      { lang, refID, projectID, siteID },
      httpOptions
    );
  }

  getMeInterventions(
    projectID: string,
    refID: string,
    constraintID: string,
    siteID?: string
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMeInterventions',
      { lang, refID, constraintID, projectID, siteID },
      httpOptions
    );
  }

  getMeIntervention(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMeIntervention',
      { lang, refID },
      httpOptions
    );
  }

  addMeInterventions(
    projectID: string,
    refID: string,
    refIDs: string[],
    siteID?: string
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/addMeInterventions',
      { lang, refID, refIDs, projectID, siteID },
      httpOptions
    );
  }

  getMeInterventionStructure(projectID: string, refID: string, siteID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMeInterventionStructure',
      { lang, refID, projectID, siteID },
      httpOptions
    );
  }

  editMeIntervention(body: any) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/editMeIntervention',
      body,
      httpOptions
    );
  }
  getMeNumberByGenderInterventions(body: any) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMeNumberByGenderInterventions',
      body,
      httpOptions
    );
  }
  getMeNumberByAgeInterventions(body: any) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMeNumberByAgeInterventions',
      body,
      httpOptions
    );
  }
  getMeNumberInterventions(body: any) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMeNumberInterventions',
      body,
      httpOptions
    );
  }
  getMeAreaCoveredInterventions(body: any) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMeAreaCoveredInterventions',
      body,
      httpOptions
    );
  }

  getMeNumberByGenderInterventionsProject(body: any) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMeNumberByGenderInterventionsProject',
      body,
      httpOptions
    );
  }
  getMeNumberByAgeInterventionsProject(body: any) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMeNumberByAgeInterventionsProject',
      body,
      httpOptions
    );
  }
  getMeNumberInterventionsProject(body: any) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMeNumberInterventionsProject',
      body,
      httpOptions
    );
  }
  getMeAreaCoveredInterventionsProject(body: any) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMeAreaCoveredInterventionsProject',
      body,
      httpOptions
    );
  }

  deleteMeIntervention(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/deleteMeIntervention',
      { refID },
      httpOptions
    );
  }
  deleteDescriptorDevelopmentBenefit(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/deleteDescriptorDevelopmentBenefit',
      { refID },
      httpOptions
    );
  }
  deleteSubgoalDevelopmentBenefit(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/deleteSubgoalDevelopmentBenefit',
      { refID },
      httpOptions
    );
  }

  getWebportalTranslation(lang: string) {
    return this.httpClient.post(
      environment.apiUrl + '/getWebportalTranslation',
      { lang },
      httpOptions
    );
  }

  getStructureDevelopmentBenefit(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getStructureDevelopmentBenefit',
      { refID, lang },
      httpOptions
    );
  }

  addDevelopmentBenefitAnalysis(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/addDevelopmentBenefitAnalysis',
      { refID },
      httpOptions
    );
  }

  getVdmInterventionCategories(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getVdmInterventionCategories',
      { lang, refID },
      httpOptions
    );
  }

  getVdmInterventions(
    refID: string,
    goalID: string,
    siteID: string | null = null,
    familyID: string | null = null
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getVdmInterventions',
      { lang, refID, goalID, siteID, familyID },
      httpOptions
    );
  }

  getVdmConstraints(
    refID: string,
    siteID: string | null = null,
    familyID: string | null = null,
    goalID: string | null = null
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getVdmConstraints',
      { lang, refID, goalID, siteID, familyID },
      httpOptions
    );
  }
  getMaterialTypeCategories(ref: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getMaterialTypeCategories',
      { lang, ref },
      httpOptions
    );
  }

  getAllActivities(body: any) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getAllActivities',
      { lang, ...body },
      httpOptions
    );
  }

  getInfoActivity(refID: string, activityType: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getInfoActivity',
      { lang, refID, activityType },
      httpOptions
    );
  }

  getActivityOtHeaders(
    refID: string,
    activityType: string,
    appVersion: number
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getActivityOtHeaders',
      { lang, refID, activityType, appVersion },
      httpOptions
    );
  }
  getActivityOtStructure(
    refID: string,
    activityType: string,
    appVersion: number
  ) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getActivityOtStructure',
      { lang, refID, activityType, appVersion },
      httpOptions
    );
  }
  getImagesActivity(refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getImagesActivity',
      { lang, refID },
      httpOptions
    );
  }
  getImagesVarieties(projectID: string, refID: string) {
    const lang = this.translate.currentLang.toUpperCase();
    return this.httpClient.post(
      environment.apiUrl + '/getImagesVarieties',
      { lang, refID, projectID },
      httpOptions
    );
  }
}
